<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title"
                  placeholder="请输入订单号"
                  label="订单号"
                  clearable></el-input>
        <el-input v-model="searchinfo.account"
                  placeholder="请输入会员账号/手机号"
                  label="会员账号/手机号"
                  clearable></el-input>
        <el-input v-model="searchinfo.name"
                  placeholder="请输入技师名称"
                  label="技师名称"
                  clearable></el-input>
        <el-select v-model="searchinfo.status" placeholder="支付状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待支付" :value="0">待支付</el-option>
          <el-option label="已支付" :value="1">已支付</el-option>
        </el-select>
        <el-select v-model="searchinfo.is_deal" placeholder="完成状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待服务" :value="0">待服务</el-option>
          <el-option label="服务中" :value="1">服务中</el-option>
          <el-option label="已完成" :value="2">已完成</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="40">
        </el-table-column>
        <el-table-column
            prop="order_sn"
            label="退款单号" width="140px">
        </el-table-column>
        <el-table-column
            label="申请会员"
            prop="account"
            width="110">
        </el-table-column>
        <el-table-column
            prop="order_o_sn"
            label="退款订单号" width="140px">
        </el-table-column>
        <el-table-column
            label="退款金额"
            prop="price" width="90">
        </el-table-column>
        <el-table-column
            label="退款信息">
          <template slot-scope="scope">
            <template v-if="scope.row.is_audit == 0">
              <span style="color:#999580;">待退款</span>
            </template>
            <template v-else>
              <div>退款状态：
                <span style="color: green;" v-if="scope.row.is_audit == 1">已退款</span>
                <span style="color: #ff0a00;" v-else>拒绝退款</span>
              </div>
              <div>操作人：{{scope.row.audit_name}}</div>
              <div>操作时间：{{ scope.row.audit_time }}</div>
              <div v-if="scope.row.audit_remark">操作备注：{{ scope.row.audit_remarks }}</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
            label="申请时间"
            width="160"
            prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
  </el-main>
</template>

<script>
export default {
  name: 'refund',
  components: {
  },
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        order_sn: '',
        is_audit:'',
        is_back:'',
        account:''
      },
      list: [],
      totalRows: 0,
      defaultuserinfo: {
      },
      projectformdata:{
        id:0,
        is_audit:0,
        audit_remarks:''
      },
      rules: {
      },
      isshowdialog: false,
      isbusy: false,
      multipleSelection:[]
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async auditrefund(item){
      this.$confirm('确定订单退款操作？').then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/manager/refund/save', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/manager/refund/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    }
  },
  mounted() {
    this.getlist(1);
  }
}
</script>

<style>
.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
.el-form-item{
  margin-bottom:12px;
}
.el-tab-pane{
  margin-top:10px;
}
</style>
