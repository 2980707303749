<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title" :placeholder="$t('depart.title')" :label="$t('depart.title')"
                  clearable></el-input>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="issearch" :loading="issearch">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="add('form')" icon="el-icon-plus">{{ $t('global.add') }}{{
            $t('depart.name')
          }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="60">
        </el-table-column>
        <el-table-column
            :label="$t('depart.title')"
            min-width="120" prop="name">
        </el-table-column>
        <el-table-column
            :label="$t('depart.province')"
            min-width="180">
          <template slot-scope="scope">{{ scope.row.province }} / {{ scope.row.city }}</template>
        </el-table-column>
        <el-table-column
            :label="$t('depart.location')"
            min-width="250" prop="location">
        </el-table-column>
        <el-table-column
            :label="$t('depart.people')"
            min-width="120" prop="people">
        </el-table-column>
        <el-table-column
            :label="$t('depart.mobile')"
            min-width="120" prop="mobile">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="100" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row)">{{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('global.created_at')"
            width="160" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="165" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="edit(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" v-if="!scope.row.is_system" @click="del(scope.row)">{{ $t('global.delete') }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="noticeformdata.id?$t('depart.editDepart'):$t('depart.addDepart')" :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form :model="noticeformdata" :rules="rules" ref="form" :show-message="false">
        <el-form-item :label="$t('depart.title')" label-width="120px" prop="name" required>
          <el-input v-model="noticeformdata.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('depart.province')" label-width="120px" prop="province" required>
          <el-cascader
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="handleChange"
          style="width:100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item :label="$t('depart.location')" label-width="120px" prop="location" required>
          <el-input v-model="noticeformdata.location" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('depart.people')" label-width="120px" prop="people" required>
          <el-input v-model="noticeformdata.people" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('depart.mobile')" label-width="120px" prop="mobile" required>
          <el-input v-model="noticeformdata.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('depart.status')" label-width="120px" required>
          <el-switch v-model="noticeformdata.status" :active-value="1" :inactive-value="0"
                     :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('depart.remarks')" label-width="120px" prop="remarks">
          <el-input type="textarea" v-model="noticeformdata.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('form')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script type="text/javascript">
import { provinceAndCityData,CodeToText,TextToCode } from "element-china-area-data";

export default {
  name: 'depart',
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        console.log(reg.test(value));
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      options: provinceAndCityData,
      selectedOptions: [],
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
      },
      list: [],
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        name: '',
        location: '',
        province: '',
        people: '',
        mobile: '',
        remarks: '',
        status: 1,
      },
      noticeformdata: {},
      rules: {
        title: [
          {min: 2, message: '机构名称必须在2个字符以上', trigger: 'blur'}
        ],
        mobile: [
          {validator: checkPhone, trigger: 'blur'}
        ]
      },
      isshowdialog: false,
      issearch: false,
      isbusy: false,
    }
  },
  methods: {
    handleChange(item) {
      let loc = [];
      item.forEach(function(code,index){
        loc.push(CodeToText[code]);
      });
      this.selectedOptions = item;
      this.noticeformdata.province = loc;
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    async edit(item) {
      this.noticeformdata = {};
      let res = await this.httpget('/manager/depart/save', {id: item.id});
      if (res.code === 0) {
        let loc = [];
        if( res.data.province ){
          loc.push(TextToCode[res.data.province].code);
        }
        if( res.data.city ){
          loc.push(TextToCode[res.data.province][res.data.city].code);
        }
        this.selectedOptions = loc;
        this.noticeformdata = res.data;
        this.isshowdialog = true;
      }
    },
    add(formName) {
      this.noticeformdata = {};
      this.noticeformdata = this.cloneData(this.defaultuserinfo);
      this.isshowdialog = true;
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.issearch = true;
      let res = await this.httpget('/manager/depart/index', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.totalRows = res.data.total;
        this.issearch = false;
      } else {
        this.issearch = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/manager/depart/save', this.noticeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/manager/depart/setenable', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.id === item.id
          })
          if (idx !== -1) {
            this.list[idx].status = Math.abs(this.list[idx].status - 1);
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async del(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/manager/depart/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    }
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
    baseurl: {
      get: function () {
        return this.$store.getters.getSerdomain;
      }
    },
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}

.edui-popup-content.edui-default {
  height: auto !important;
}

.edui-combox-body.edui-default {
  height: 25px;

}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px !important;
}

.edui-default .edui-toolbar .edui-splitborder {
  height: 25px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
  height: 25px !important;
  width: 13px !important;
}

#edui_fixedlayer {
  z-index: 100000 !important;
}
</style>
