<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title"
                  placeholder="请输入订单号"
                  label="订单号"
                  clearable></el-input>
        <el-input v-model="searchinfo.account"
                  placeholder="请输入会员账号/手机号"
                  label="会员账号/手机号"
                  clearable></el-input>
        <el-input v-model="searchinfo.name"
                  placeholder="请输入技师名称"
                  label="技师名称"
                  clearable></el-input>
        <el-select v-model="searchinfo.depart_id" placeholder="请选择分店" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <template v-for="item in shoplist">
          <el-option :label="item.name" :value="item.id">{{ item.name }}</el-option>
          </template>
        </el-select>
        <el-select v-model="searchinfo.status" placeholder="支付状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待支付" :value="0">待支付</el-option>
          <el-option label="已支付" :value="1">已支付</el-option>
        </el-select>
        <el-select v-model="searchinfo.is_deal" placeholder="完成状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待服务" :value="0">待服务</el-option>
          <el-option label="服务中" :value="1">服务中</el-option>
          <el-option label="已完成" :value="2">已完成</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="40">
        </el-table-column>
        <el-table-column
            prop="order_sn"
            label="订单号" width="140px">
        </el-table-column>
        <el-table-column
            prop="name"
            label="分店名称" width="120">
        </el-table-column>
        <el-table-column
            label="会员账号"
            prop="account"
            width="110">
        </el-table-column>
        <el-table-column
            label="预约技师"
            prop="masseur_name" width="80">
        </el-table-column>
        <el-table-column
            label="服务项目"
            prop="projects" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.projects ? scope.row.projects : '---' }}
          </template>
        </el-table-column>
        <el-table-column
                label="预约人" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.is_self == 0">本人</span>
            <span v-else>{{scope.row.oname}}<br/>{{scope.row.omobile}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="预约时间"
            width="140">
          <template slot-scope="scope">
            {{ scope.row.daytime }} {{ scope.row.datetime }}
          </template>
        </el-table-column>
        <el-table-column
            label="预约金(元)"
            prop="price" width="90">
        </el-table-column>
        <el-table-column
            label="支付状态"
            prop="pay_status" width="90">
          <template slot-scope="scope">
            <el-link v-if="scope.row.is_refund == 2">已退款</el-link>
            <el-link :type="scope.row.pay_status?'success':'danger'" v-else>{{ scope.row.pay_status ? '已支付' : '待支付' }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            label="服务状态"
            prop="is_deal" width="90">
          <template slot-scope="scope">
            <el-link v-if="scope.row.is_refund == 2">已关闭</el-link>
            <el-link :type="scope.row.is_deal?'success':'danger'" v-else>{{ scope.row.is_deal==0 ? '待服务' :(scope.row.is_deal == 1 ?  '服务中' :  (scope.row.is_deal == 2 ? '待付尾款' : (scope.row.is_deal==3 ? '待评价' : '已完成'))) }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            label="尾款(元)"
            prop="final_price" width="90">
          <template slot-scope="scope">
            {{ scope.row.final_price > 0 ? scope.row.final_price : '---'}}
          </template>
        </el-table-column>
        <el-table-column
            label="尾款支付"
            prop="is_final" width="90">
          <template slot-scope="scope">
            <el-link :type="scope.row.is_final?'success':'danger'">{{ scope.row.final_price > 0 ? (scope.row.is_final ? '已支付' : '待支付' ) : '---' }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            label="合计(元)"
            prop="total_price" width="90">
          <template slot-scope="scope">
            {{ scope.row.final_price > 0 ? scope.row.total_price : '---'}}
          </template>
        </el-table-column>
        <el-table-column
            label="下单时间"
            width="160" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="65" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="showorder(scope.row)">查看</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog title="查看订单"
               :visible.sync="isshowdialogs"
               :close-on-click-modal="false">
      <el-form :model="projectformdata" inline="true"  :rules="rules" :show-message="false">
        <el-form-item label="订单编号" label-width="100px" prop="order_sn">
          <el-input type="text" v-model="projectformdata.order_sn" autocomplete="off" disabled class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="会员账号" label-width="100px" prop="account">
          <el-input type="text" v-model="projectformdata.account" autocomplete="off" disabled class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="联系手机" label-width="100px" prop="mobile">
          <el-input type="text" v-model="projectformdata.mobile" autocomplete="off" disabled class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="预约技师" label-width="100px" prop="price">
          <el-input type="text" v-model="projectformdata.masseur_name" disabled autocomplete="off" class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="预约项目" label-width="100px" prop="projects">
          <el-input type="text" v-model="projectformdata.projects" disabled autocomplete="off" class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="预约时间" label-width="100px" prop="daytime">
          <el-input type="text" v-model="projectformdata.daytime+' '+projectformdata.datetime" disabled autocomplete="off" class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="预约费用" label-width="100px" prop="price">
          <el-input type="number" v-model="projectformdata.price" disabled autocomplete="off" class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="支付状态" label-width="100px">
          <el-input type="text" v-model="projectformdata.pay_status==0?'待支付':'已支付'" disabled autocomplete="off" class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="支付时间" label-width="100px" prop="pay_time">
          <el-input type="text" v-model="projectformdata.pay_time" disabled class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="尾款金额" label-width="100px" prop="final_price">
          <el-input type="text" v-model="projectformdata.final_price" disabled class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="支付尾款状态" label-width="100px" prop="is_final">
          <el-input type="text" v-model="projectformdata.is_final==0?'待支付':'已支付'" disabled class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="尾款支付时间" label-width="100px" prop="final_time">
          <el-input type="text" v-model="projectformdata.final_time" disabled class="iw-xlg" disabled></el-input>
        </el-form-item>
        <el-form-item label="下单时间" label-width="100px" prop="created_at">
          <el-input type="text" v-model="projectformdata.created_at" disabled class="iw-xlg"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  name: 'order',
  components: {
  },
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
        status:'',
        is_deal:'',
        account:'',
        name:'',
      },
      list: [],
      totalRows: 0,
      project:[],
      defaultuserinfo: {
      },
      projectformdata:[],
      rules: {
      },
      projectarr:[],
      shoplist:[],
      projectdefaultarr:[],
      isshowdialog: false,
      isshowdialogs:false,
      isbusy: false,
      multipleSelection:[]
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/manager/order/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },

    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      this.projectformdata.projects = this.multipleSelection;
      let res = await this.httppost('/manager/order/save', this.projectformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist();
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async showorder(item){
      this.projectformdata = null
      this.projectformdata = this.cloneData(item);
      this.isshowdialogs = true;
    },
    async projects(item) {
      this.isbusy = true;
      this.projectformdata = null
      this.projectformdata = this.cloneData(item);
      let res = await this.httpput('/manager/order/project', {id: item.id,mid:item.masseur_id});
      if (res.code === 0) {
        this.projectarr = res.data;
        let proids = this.projectformdata.projects_ids.split(',');
        if( this.projectarr.length >0 && proids.length > 0 ){
          this.$nextTick(function() {
            this.projectarr.forEach((u,ids)=>{
              proids.forEach((k)=>{
                if(u.id == k){
                  this.$refs.multipleTable.toggleRowSelection(this.projectarr[ids],true);
                }
              })
            })
          })
        }
        this.isshowdialog = true;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.isshowdialog = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async departlist() {
      this.isbusy = true;
      let res = await this.httpput('/manager/order/shop');
      if (res.code === 0) {
        this.shoplist = res.data;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    }
  },
  mounted() {
    this.getlist(1);
    this.departlist();
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
.el-form-item{
  margin-bottom:12px;
}
.el-tab-pane{
  margin-top:10px;
}
</style>
