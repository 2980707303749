<template>
  <el-main>
    <div style="background:#fff;">
      <el-form :model="noticeformdata" :rules="rules" ref="form" :show-message="false">
      <el-tabs type="border-card">
        <el-tab-pane label="基础配置">
          <el-form-item label="时间设置：" label-width="120px"
                        prop="timestr">
            <el-input type="text" v-model="noticeformdata.timestr" autocomplete="off" placeholder="请输入时间设置用,号隔开"></el-input>
          </el-form-item>
          <el-form-item label="预约时间区间：" label-width="120px"
                        prop="timestr">
            <el-date-picker
                v-model="noticeformdata.classday"
                type="daterange"
                range-separator="至 "
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="积分比例：" label-width="120px"
                        prop="integral">
            <el-input type="text" v-model="noticeformdata.integral" autocomplete="off" placeholder="请输入积分和人民币比例" class="iw-xlg"></el-input>
          </el-form-item>
          <el-form-item label="退款时间限制：" label-width="120px"
                        prop="days">
            <el-input type="text" class="iw-xlg" v-model="noticeformdata.days" autocomplete="off" placeholder="请输入允许提前几天退款">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="isbusy" @click="dosubmit('form')">确定保存</el-button>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="关于我们">
            <el-form-item prop="aboutus">
              <vue-ueditor-wrap v-model="noticeformdata.aboutus" :config="myConfig"></vue-ueditor-wrap>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="isbusy" @click="dosubmit('form')">确定保存</el-button>
            </el-form-item>
        </el-tab-pane>
      </el-tabs>
      </el-form>
    </div>
  </el-main>
</template>

<script type="text/javascript">
import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
  name: 'news',
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      noticeformdata:{
        id:0,
        aboutus:'',
        days:'',
        timestr:'',
        integral:1,
        classday:[],
      },
      isbusy:false,
      rules:{},
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
        serverUrl: process.env.VUE_APP_API_ROOT+'/system/upload/uploadueditor?storage=ueditor',
      }
    }
  },
  methods: {
    async getlist() {
      let res = await this.httpget('/manager/setting/index');
      if (res.code === 0) {
        this.noticeformdata.id = res.data.id;
        this.noticeformdata.aboutus = !res.data.aboutus ? '' : res.data.aboutus;
        this.noticeformdata.days = !res.data.days ? '' : res.data.days;
        this.noticeformdata.timestr = !res.data.timestr ? '' : res.data.timestr;
        this.noticeformdata.integral = !res.data.integral ? '1.00' : res.data.integral;
        this.noticeformdata.classday.push(res.data.daybtime);
        this.noticeformdata.classday.push(res.data.dayetime);
      }
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      if( this.noticeformdata.classday && this.noticeformdata.classday[0]){
        this.noticeformdata.daybtime = this.noticeformdata.classday[0];
      } else {
        this.noticeformdata.daybtime = "";
      }
      if( this.noticeformdata.classday && this.noticeformdata.classday[1]){
        this.noticeformdata.dayetime = this.noticeformdata.classday[1];
      } else {
        this.noticeformdata.dayetime = "";
      }
      let res = await this.httppost('/manager/setting/save', this.noticeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
  },
  mounted() {
    this.getlist();
  },
  computed: {
    baseurl: {
      get: function () {
        return this.$store.getters.getSerdomain;
      }
    },
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}
.el-date-editor .el-range-separator{
  width:10%;
}

.edui-popup-content.edui-default {
  height: auto !important;
}

.edui-combox-body.edui-default {
  height: 25px;

}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px !important;
}

.edui-default .edui-toolbar .edui-splitborder {
  height: 25px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
  height: 25px !important;
  width: 13px !important;
}

#edui_fixedlayer {
  z-index: 100000 !important;
}
</style>
