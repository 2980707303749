<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title"
                  placeholder="用户账号"
                  label="用户账号"
                  clearable></el-input>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="add('form')">手动账变</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="60">
        </el-table-column>
        <el-table-column
            prop="account"
            label="用户账号" width="200">
        </el-table-column>
        <el-table-column
                prop="opt_type"
                label="类型" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.opt_type == 0">充值</span>
            <span v-else>消费</span>
          </template>
        </el-table-column>
        <el-table-column
            label="变更前金额"
            prop="front_amount" width="150">
        </el-table-column>
        <el-table-column
            label="金额"
            prop="amount" width="150">
        </el-table-column>
        <el-table-column
            label="变更后金额"
            prop="last_amount" width="150">
        </el-table-column>
        <el-table-column
            label="变更备注"
            prop="remarks">
        </el-table-column>
        <el-table-column
            label="记录时间"
            width="180" prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog title="手动账变" :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form :model="noticeformdata" :rules="rules" ref="form" :show-message="false">
        <el-form-item label="账变账号" label-width="120px" required>
          <el-input v-model="noticeformdata.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="变更类型" label-width="120px" required>
          <el-radio-group v-model="noticeformdata.btype">
            <el-radio :label="0">加款</el-radio>
            <el-radio :label="1">减款</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="账变金额" label-width="120px" required>
          <el-input v-model="noticeformdata.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="变更说明" label-width="120px" required>
          <el-input type="textarea" v-model="noticeformdata.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('form')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  name: 'records',
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        opt_type:'',
        amount:'',
        btime:'',
        etime:'',
        title: '',
      },
      noticeformdata:{
        btype:0,
        account:'',
        price:'',
        remarks:'',
      },
      list: [],
      totalRows: 0,
      gradeformdata: {},
      rules: {},
      isbusy: false,
      isshowdialog:false,
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    add(formName) {
      this.isshowdialog = true;
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      });
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/manager/recharge/modify', this.noticeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/manager/recharge/records', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
    langlist: {
      get: function () {
        return this.$store.getters.getLanglist;
      }
    },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
</style>
