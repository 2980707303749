<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-select v-model="searchinfo.status" placeholder="处理状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="申请中" :value="0">申请中</el-option>
          <el-option label="同意申请" :value="1">同意申请</el-option>
          <el-option label="拒绝申请" :value="2">拒绝申请</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
                label="ID"
                prop="id" width="60">
        </el-table-column>
        <el-table-column
                prop="mobile"
                label="会员账号" width="200">
        </el-table-column>
        <el-table-column
                label="旧等级"
                prop="old_grade_name" width="120">
        </el-table-column>
        <el-table-column
                label="申请等级"
                prop="apply_grade_name" width="120">
        </el-table-column>
        <el-table-column
                :label="$t('grade.status')"
                prop="status" width="120">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.status == 0">申请中</el-tag>
            <el-tag type="success" v-else-if="scope.row.status == 1">同意申请</el-tag>
            <el-tag type="warning" v-else-if="scope.row.status == 2">拒绝申请</el-tag>
          </template>
        </el-table-column>
        <el-table-column
                label="处理时间"
                prop="deal_time" width="180">
        </el-table-column>
        <el-table-column
                label="处理备注"
                prop="deal_remark">
        </el-table-column>
        <el-table-column
                :label="$t('grade.created_at')"
                width="180" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="165" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="edit(scope.row)" v-if="scope.row.status == 0">{{ $t('global.edit') }}</el-link>
            <span v-else>----</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
              background
              @size-change="SizeChange"
              @current-change="getlist"
              :current-page="searchinfo.page"
              :page-sizes="PAGE.pageSizes"
              :page-size="searchinfo.pagesize"
              :layout="PAGE.layout"
              :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog title="处理等级申请"
               :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form :model="gradeformdata" :rules="rules" ref="userform" :show-message="false">
        <el-form-item label="会员账号" label-width="100px">
          {{gradeformdata.account}}
        </el-form-item>
        <el-form-item label="会员原等级" label-width="100px">
          {{gradeformdata.old_grade_name}}
        </el-form-item>
        <el-form-item label="申请等级" label-width="100px">
          {{gradeformdata.apply_grade_name}}
        </el-form-item>
        <el-form-item label="申请状态" label-width="100px">
          <el-radio-group v-model="gradeformdata.status">
            <el-radio :label="0">申请中</el-radio>
            <el-radio :label="1">同意申请</el-radio>
            <el-radio :label="2">拒绝申请</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="处理备注" label-width="100px">
          <el-input type="textarea" v-model="gradeformdata.deal_remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
  import {TextToCode} from "element-china-area-data";

  export default {
    name: 'apply',
    data() {
      return {
        searchinfo: {
          page: 1,
          pagesize: this.PAGE.pageSize,
          title: '',
        },
        list: [],
        totalRows: 0,
        grade:[],
        gradeformdata: {},
        rules: {},
        isshowdialog: false,
        isbusy: false,
      }
    },
    methods: {
      SizeChange(val) {
        this.searchinfo.pagesize = val;
        this.getlist(1);
      },
      async edit(item) {
        this.noticeformdata = {};
        this.gradeformdata = this.cloneData(item);
        this.isshowdialog = true;
      },
      async dosearch() {
        this.getlist(1);
      },
      async getuserinfo() {
        if( !this.gradeformdata.account ){
          this.$message({type: 'error', message: '请输入变更会员账号'});
          return false;
        }
        this.isbusy = true;
        let res = await this.httppost('/manager/apply/getuserinfo', this.gradeformdata);
        if (res.code === 0) {
          this.isbusy = false;
          this.gradeformdata.old_grade = res.data.grade_id;
          this.gradeformdata.old_gradename = res.data.name;
          this.gradeformdata.mem_id = res.data.id;
        } else {
          this.isbusy = false;
        }
      },
      async getlist(page) {
        this.searchinfo.page = page || this.searchinfo.page;
        this.isbusy = true;
        let res = await this.httpget('/manager/apply/index', this.searchinfo);
        if (res.code === 0) {
          this.list = res.data.data;
          this.totalRows = res.data.total;
          this.isbusy = false;
        } else {
          this.isbusy = false;
        }
      },
      dosubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.suresubmit();
          } else {
            return false;
          }
        });
      },
      async suresubmit() {
        this.isbusy = true;
        if( this.gradeformdata.status == 2 && !this.gradeformdata.deal_remark ){
          this.$message({type: 'error', message: "请输入处理备注"});
          this.isbusy = false;
          return false;
        }
        let res = await this.httppost('/manager/apply/save', this.gradeformdata);
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist(1);
          this.gradeformdata = {};
          this.isshowdialog = false;
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      },
      async setenable(item) {
        this.$confirm(this.$t('global.areyousure')).then(async () => {
          this.isbusy = true;
          let res = await this.httpput('/manager/apply/setenable', {id: item.id});
          if (res.code === 0) {
            this.$message({type: 'success', message: res.msg});
            let idx = this.list.findIndex((value) => {
              return value.id === item.id
            })
            if (idx !== -1) {
              this.list[idx].status = Math.abs(this.list[idx].status - 1);
            }
            this.isbusy = false;
          } else {
            this.isbusy = false;
            this.$message({type: 'error', message: res.msg});
          }
        }).catch(() => {
          this.isbusy = false;
          this.$message({type: 'info', message: 'Canceled'});
        });
      },
      async deluser(item) {
        this.$confirm(this.$t('global.areyousure')).then(async () => {
          this.isbusy = true;
          let res = await this.httpput('/manager/apply/delete', {id: item.id});
          if (res.code === 0) {
            this.$message({type: 'success', message: res.msg});
            this.getlist();
          } else {
            this.$message({type: 'error', message: res.msg});
          }
          this.isbusy = false;
        }).catch(() => {
          this.isbusy = false;
          this.$message({type: 'info', message: 'Canceled'});
        });
      },
      async getgrade() {
        let res = await this.httpget('/manager/publicapi/getgrade');
        if (res.code === 0) {
          this.grade = res.data;
        }
      },
    },
    mounted() {
      this.getlist(1);
      this.getgrade();
    },
    computed: {
      langlist: {
        get: function () {
          return this.$store.getters.getLanglist;
        }
      },
    },
  }
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
</style>