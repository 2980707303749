<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title" placeholder="请输入用户账号" label="用户账号"
                  clearable></el-input>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="issearch" :loading="issearch">
          {{ $t('global.search') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="80">
        </el-table-column>
        <el-table-column
            label="用户账号"
            min-width="120">
          <template slot-scope="scope">
            <el-popover
                placement="right"
                title=""
                trigger="hover">
              <img :src="scope.row.avatar" style="width:300px;"/>
              <img slot="reference" :src="scope.row.avatar" :alt="scope.row.avatar"
                   style="height:40px;">
            </el-popover>
            <div style="display: inline-block; height: 40px; line-height: 40px; vertical-align:top; margin-left:10px;">{{ scope.row.account }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="会员等级"
          width="120"
          prop="gname">
        </el-table-column>
        <el-table-column
            label="联系手机"
            width="120"
            prop="mobile">
        </el-table-column>
        <el-table-column
            label="最后登录ip"
            width="150"
            prop="last_ip">
        </el-table-column>
        <el-table-column
            label="最后登录时间"
            width="170"
            prop="last_time">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="100" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row)">{{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('global.created_at')"
            width="170" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="105" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="showDetaile(scope.row)">查看</el-link>
            <el-link class="optlink" @click="edit(scope.row)">{{ $t('global.edit') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog title="查看详情"
               :visible.sync="isshowdialog"
               :close-on-click-modal="false"
               custom-class="w90">
      <el-form :inline="true" :model="userformdata" ref="userform" :show-message="false">
        <el-tabs type="card" style="padding-bottom: 20px;">
          <el-tab-pane label="基本信息">
            <div style="padding-top:10px;">
              <el-form-item label="会员账号" label-width="100px">
                <el-input v-model="userformdata.account" readonly disabled autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="会员姓名" label-width="100px">
                <el-input v-model="userformdata.name" readonly disabled autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="出生日期" readonly label-width="100px">
                <el-input v-model="userformdata.birthday" disabled readonly autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" readonly label-width="100px">
                <el-input v-model="userformdata.id_card" disabled readonly autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="居住地址" readonly label-width="100px">
                <el-input v-model="userformdata.address" disabled readonly autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="最后登陆ip" readonly label-width="100px">
                <el-input v-model="userformdata.last_ip" disabled readonly autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="最后登陆时间" readonly label-width="100px">
                <el-input v-model="userformdata.last_time" disabled readonly autocomplete="off"></el-input>
              </el-form-item>
            </div>
          </el-tab-pane>
          <el-tab-pane label="会员预约记录" class="sertab">
            <el-table :data="orderlist"
                      tooltip-effect="dark"
                      style="width: 100%">
              <el-table-column
                  label="ID"
                  prop="id" width="50">
              </el-table-column>
              <el-table-column
                  label="订单编号"
                  min-width="140"
                  prop="order_sn">
              </el-table-column>
              <el-table-column
                  label="支付状态"
                  width="90"
                  prop="pay_status">
                <template slot-scope="scope">
                  <el-link v-if="scope.row.is_refund == 2">已退款</el-link>
                  <el-link :type="scope.row.pay_status?'success':'danger'" v-else>{{ scope.row.pay_status ? '已支付' : '待支付' }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                  label="服务技师"
                  prop="name" width="90">
              </el-table-column>
              <el-table-column
                  label="服务状态"
                  prop="is_deal" width="90">
                <template slot-scope="scope">
                  <el-link v-if="scope.row.is_refund == 2">已关闭</el-link>
                  <el-link :type="scope.row.is_deal?'success':'danger'" v-else>{{ scope.row.is_deal==0 ? '待服务' :(scope.row.is_deal == 1 ?  '服务中' :  (scope.row.is_deal == 2 ? '待付尾款' : (scope.row.is_deal==3 ? '待评价' : '已完成'))) }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                  label="尾款(元)"
                  prop="final_price" width="90">
                <template slot-scope="scope">
                  {{ scope.row.final_price > 0 ? scope.row.final_price : '---'}}
                </template>
              </el-table-column>
              <el-table-column
                  label="尾款支付"
                  prop="is_final" width="90">
                <template slot-scope="scope">
                  <el-link :type="scope.row.is_final?'success':'danger'">{{ scope.row.final_price > 0 ? (scope.row.is_final ? '已支付' : '待支付' ) : '---' }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                  label="合计(元)"
                  prop="total_price" width="90">
                <template slot-scope="scope">
                  {{ scope.row.final_price > 0 ? scope.row.total_price : '---'}}
                </template>
              </el-table-column>
              <el-table-column
                  label="技师陈述"
                  prop="masseur_remarks" width="300">
              </el-table-column>
              <el-table-column
                  label="客户评价"
                  prop="custom_remarks" width="300">
              </el-table-column>
            </el-table>
            <el-pagination
                background
                @size-change="SizeChangeOrder"
                @current-change="getorderlist"
                :current-page="searchorder.page"
                :page-sizes="PAGE.pageSizes"
                :page-size="searchorder.pagesize"
                :layout="PAGE.layout"
                :total="totalOrderRows" style="margin-top:10px; padding-bottom: 10px;">
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-dialog>
    <el-dialog title="修改会员信息" :visible.sync="isshoweditdialog"
               :close-on-click-modal="false" custom-class="w40">
      <el-form :model="userformdata" :rules="rules" ref="form" :show-message="false">
        <el-form-item label="会员账号" label-width="100px">
          <el-input v-model="userformdata.account" readonly disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="会员等级" label-width="100px">
          <el-select v-model="userformdata.grade_id" placeholder="请选择会员等级" clearable>
            <el-option label="全部" value="">全部</el-option>
            <template v-for="item in grade">
              <el-option :label="item.name" :value="item.id">{{ item.name }}</el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="会员姓名" label-width="100px">
          <el-input v-model="userformdata.name" readonly disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出生日期" readonly label-width="100px">
          <el-input v-model="userformdata.birthday" disabled readonly autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" readonly label-width="100px">
          <el-input v-model="userformdata.id_card" disabled readonly autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="居住地址" readonly label-width="100px">
          <el-input v-model="userformdata.address" disabled readonly autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="最后登陆ip" readonly label-width="100px">
          <el-input v-model="userformdata.last_ip" disabled readonly autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="最后登陆时间" readonly label-width="100px">
          <el-input v-model="userformdata.last_time" disabled readonly autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshoweditdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('form')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script type="text/javascript">

export default {
  name: 'member',
  components: {
  },
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
      },
      searchorder:{
        page:1,
        pagesize: this.PAGE.pageSize,
      },
      list: [],
      orderlist:[],
      totalOrderRows: 0,
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        title: '',
        grade_id:'',
        descript: '',
        picture: '',
        contents: '',
        sort: 0,
        status: 1,
      },
      userformdata: {},
      rules: {
      },
      grade:[],
      isshowdialog: false,
      issearch: false,
      isbusy: false,
      isshoweditdialog:false,
    }
  },
  methods: {
    SizeChangeOrder(val){
      this.searchorder.pagesize = val;
      this.getorderlist(1);
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    async getorderlist(page,mid) {
      this.searchorder.page = page || this.searchorder.page;
      this.searchorder.member_id = mid;
      let res = await this.httpget('/manager/member/order', this.searchorder);
      if (res.code === 0) {
        this.orderlist = res.data.data;
        this.totalOrderRows = res.data.total;
      }
    },
    async getgrade() {
      let res = await this.httpget('/manager/publicapi/getgrade');
      if (res.code === 0) {
        this.grade = res.data;
      }
    },
    async showDetaile(item) {
      this.getorderlist(1,item.id);
      this.userformdata = {};
      let res = await this.httpget('/manager/member/getinfo', {id: item.id});
      if (res.code === 0) {
        this.userformdata = res.data;
        this.isshowdialog = true;
      }
    },
    async edit(item) {
      this.userformdata = {};
      let res = await this.httpget('/manager/member/getinfo', {id: item.id});
      if (res.code === 0) {
        this.userformdata = res.data;
        this.isshoweditdialog = true;
      }
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.issearch = true;
      let res = await this.httpget('/manager/member/index', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.totalRows = res.data.total;
        this.issearch = false;
      } else {
        this.issearch = false;
      }

    },

    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/manager/member/save', this.userformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.userformdata = {};
        this.isshoweditdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },

    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/manager/member/setenable', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.id === item.id
          })
          if (idx !== -1) {
            this.list[idx].status = Math.abs(this.list[idx].status - 1);
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    }
  },
  mounted() {
    this.getlist(1);
    this.getgrade();
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}

.edui-popup-content.edui-default {
  height: auto !important;
}

.edui-combox-body.edui-default {
  height: 25px;

}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px !important;
}

.edui-default .edui-toolbar .edui-splitborder {
  height: 25px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
  height: 25px !important;
  width: 13px !important;
}

#edui_fixedlayer {
  z-index: 100000 !important;
}
</style>
