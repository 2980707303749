<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.remarks"
                  placeholder="请输入说明关键字"
                  label="说明关键字"
                  clearable></el-input>
        <el-select v-model="searchinfo.depart_id" placeholder="请选择分店" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <template v-for="item in shoplist">
            <el-option :label="item.name" :value="item.id">{{ item.name }}</el-option>
          </template>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="addgrade('userform')" icon="el-icon-plus">添加资金管理</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            type="index"
            width="50">
        </el-table-column>
        <el-table-column
            prop="name"
            label="分店名称">
        </el-table-column>
        <el-table-column
            label="账变前金额"
            prop="front_amount" width="120">
        </el-table-column>
        <el-table-column
            label="账变金额"
            prop="amount" width="120">
        </el-table-column>
        <el-table-column
            label="账变后金额"
            prop="last_amount" width="120">
        </el-table-column>
        <el-table-column
            label="账变说明"
            prop="remarks">
        </el-table-column>
        <el-table-column
            label="是否支付"
            prop="is_pay" width="120">
          <template slot-scope="scope">
            <el-link
                :type="scope.row.is_pay?'success':'danger'"
                >{{ scope.row.is_pay ? '已支付' : '待支付' }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('grade.created_at')"
            width="160" prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="添加资金管理记录"
               :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form :model="gradeformdata" :rules="rules" ref="userform" :show-message="false">
        <el-form-item label="分店名称" label-width="100px" prop="depart_id" required>
          <el-select v-model="gradeformdata.depart_id" placeholder="请选择分店" clearable>
            <el-option label="全部" value="">全部</el-option>
            <template v-for="item in shoplist">
              <el-option :label="item.name" :value="item.id">{{ item.name }}</el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="账变金额" label-width="100px" prop="amount" required>
          <el-input type="number" v-model="gradeformdata.amount" min="1" autocomplete="off" class="iw-xlg"></el-input>
        </el-form-item>
        <el-form-item label="是否支付" label-width="100px" required>
          <el-switch v-model="gradeformdata.is_pay" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')"
                     :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item label="账变说明" label-width="100px" prop="remarks" required>
          <el-input type="textarea" v-model="gradeformdata.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  name: 'user',
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        depart_id:'',
        remarks: '',
      },
      list: [],
      shoplist:[],
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        depart_id: '',
        remarks: 0,
        is_pay: '',
        amount:'',
      },
      gradeformdata: {},
      rules: {},
      isshowdialog: false,
      isbusy: false,
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    addgrade(formName) {
      this.gradeformdata = this.cloneData(this.defaultuserinfo);
      this.editlang = 'en_US'
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/manager/fundlogs/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/manager/fundlogs/save', this.gradeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.gradeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async departlist() {
      this.isbusy = true;
      let res = await this.httpput('/manager/publicapi/getdepart');
      if (res.code === 0) {
        this.shoplist = res.data;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    }
  },
  mounted() {
    this.getlist(1);
    this.departlist();
  },
  computed: {
    langlist: {
      get: function () {
        return this.$store.getters.getLanglist;
      }
    },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
</style>
